<template>
  <v-app>
    <v-container class="fill-height">
      <v-row justify="center">
        <v-col cols="12" md="4">
          <v-card class="elevation-12 card" color="black">
            <br />
            <v-layout mt-6 column align-center>
              <v-flex>
                <v-avatar class="profile" height="100" width="150" tile>
                  <v-img :src="require('@/assets/logo.png')"></v-img>
                </v-avatar>
              </v-flex>

              <v-card-text :class="`${fgColor}--text`">
                <h1 class="text-center headline mb-3">Circuito App</h1>
              </v-card-text>
            </v-layout>

            <v-card-text>
              <v-card>
                <v-row class>
                  <v-col cols="12" class="pt-5 pb-5 white">
                    <v-card-text>
                      <v-form
                        class="singin-form"
                        @submit.prevent="login"
                        id="login-form"
                      >
                        <h1
                          class="text-center display-1 mb-10"
                          :class="`${bgColor}--text`"
                        >
                          Iniciar sesión
                        </h1>

                        <v-alert
                          v-if="user_error != null"
                          text
                          outlined
                          color="deep-orange"
                          icon="mdi-fire"
                        >
                          {{ user_error }}
                        </v-alert>

                        <v-text-field
                          v-model="user.username"
                          :color="bgColor"
                          :error-messages="userUsernameErrors"
                          required
                          clearable
                          label="Usuario"
                          name="login_user"
                          append-icon="mdi-account"
                          type="text"
                          @input="$v.user.username.$touch()"
                          @blur="$v.user.username.$touch()"
                        ></v-text-field>

                        <v-text-field
                          v-model="user.password"
                          :append-icon="password_show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="password_show ? 'text' : 'password'"
                          :color="bgColor"
                          :error-messages="userPasswordErrors"
                          clearable
                          required
                          label="Contraseña"
                          @click:append="password_show = !password_show"
                          @input="$v.user.password.$touch()"
                          @blur="$v.user.password.$touch()"
                        ></v-text-field>

                        <div class="text-center mt-6">
                          <v-btn
                            type="submit"
                            block
                            :color="bgColor"
                            dark
                            form="login-form"
                            >Entrar</v-btn
                          >
                        </div>
                      </v-form>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <footer-component />
    </v-container>
  </v-app>
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    user: {
      username: { required },
      password: { required },
    },
  },
  name: "session",
  components: {
    "footer-component": FooterComponent,
  },
  props: {
    source: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "grey",
    },
    fgColor: {
      type: String,
      default: "white",
    },
  },
  data: () => ({
    // Data Form
    user_loading: false,
    user_error: null,
    user: {
      username: null,
      password: null,
    },
    password_show: false,
  }),
  methods: {
    async login() {
      this.$v.user.$touch();

      if (!this.$v.user.$invalid) {
        this.user_loading = true;
        this.user_error = null;

        this.$axios
          .post(
            "https://fiel.compratec.com.mx/backend/public/api/user/login",
            this.user
          )
          .then((response) => {
            if (response.data == "") {
              this.user_error = "No se encontro el usuario";
            } else {
              this.$store.commit("saveToken", response.data);
              this.$router.push("/register/index");
            }
          })
          .catch((error) => {
            console.log(error.response.data);
          })
          .finally(() => (this.user_loading = false));
      }
    },
  },
  computed: {
    userUsernameErrors() {
      const errors = [];
      if (!this.$v.user.username.$dirty) return errors;
      !this.$v.user.username.required && errors.push("Usuario es requerido");
      return errors;
    },
    userPasswordErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) return errors;
      !this.$v.user.password.required && errors.push("Contraseña es requerido");
      return errors;
    },
  }
};
</script>
